.dc-locations-wrap {
  height: 100%;
}

.dc-locations {
  &-search {
    .select-broker-placeholder {
      position: absolute;
      left: 0;
      bottom: 0;
      margin-left: 14px;
      margin-bottom: 14px;
    }

    .broker-select-form {
      position: relative;

      .ui-form-row {
        @media (min-width: 1024px) {
          flex-direction: column;
        }
      }
      .broker-select-icon {
        position: absolute;
        top: 44px;
        right: 10px;
        fill: #fff;
      }
      .broker-select {
        background: #020607;
        color: #fff;
        &::placeholder {
          color: #fff;
        }
      }

      .broker-list {
        list-style: none;
        margin-top: -8px;
        padding: 0;
        box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.1);
        border-radius: 8px;
        overflow: scroll;
        max-height: 0;
        transition: .3s ease-in-out;
        position: absolute;
        width: 100%;
        z-index: 2;
        color: #fff;
        &.open {
          border: 1px solid #fff;
          max-height: 320px;
          overflow: auto;
        }
        &__list {
          position: relative;
          background-color: #020607;
          padding: 8px 16px;
          gap: 12px;
          display: flex;
          align-items: center;
          cursor: pointer;
          transition: .3s;
          opacity: 1;
          font-weight: 500;

          &:hover {
            background-color: #1D1E1D;
          }
          &.closed {
            max-height: 0;
            overflow: hidden;
            padding: 0;
            opacity: 0;
          }
        }
      }
    }
    .latency-panels {
      display: flex;
      flex-wrap: nowrap;
      gap: 16px;

      &__panel {
        border: 1px solid var(--border-light-color);
        border-radius: 5px;
        background: #1D1E1D;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 24px 16px;
        position: relative;
        flex: 1 1 calc(50% - 16px);

        @media (min-width: 768px) {
          padding: 24px;
        }

        .tag {
          border-radius: 4px;
          background: $brand-color;
          color: #030303;
          position: absolute;
          top: 0;
          right: 0;
          font-size: 12px;
          font-weight: 600;
          padding: 4px 8px;
        }
        .ping-num {
          color: $brand-color;
          font-size: 20px;
          font-weight: 700;
          line-height: 22px;
        }
      }

      &__flag {
        width: 20px;
        margin-right: 4px;
        border-radius: 2px;
      }
    }
  }
  &__title,
  &__subheading {
    text-align: left;

    @media (min-width: 768px) {
      text-align: center;
    }
  }

  &__subheading {
    margin: 16px 0 30px !important;
    font-size: 12px;

    @media (min-width: 768px) {
      font-size: inherit;
    }
  }

  .mt4-equinix {
    @include mt4-equinix-signal;

    margin: 0 auto 0;
    width: 100%;
    max-width: 500px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    z-index: 3;

    @media (min-width: 768px) {
      max-width: 400px;
    }

    @media (min-width: 1024px) {
      max-width: 500px;
    }

    svg {
      z-index: 2;
      max-width: unset;

      @media (min-width: 768px) {
        max-width: 62px;
      }
    }

    &__signal {
      position: absolute;
      top: 40%;
      left: 53px;
      right: 56px;
      height: 2px;
      overflow: hidden;

      @media (min-width: 768px) {
        left: 47px;
        right: 50px;
      }

      &::before {
        content: '';
        display: block;
        width: 100%;
        height: 100%;
        animation: mt4-equinix-signal 1.5s ease-in-out infinite;
      }
    }
  }

  &__globe {
    margin-top: 53px;
    display: none;

    @media (min-width: 768px) {
      display: block;
      position: relative;
    }

    &.move-right {
      right: -70px;
    }

    img {
      width: 100%;
    }

    .globe-location {
      position: absolute;
      background-color: #202020;
      padding: 3px 8px;
      border-radius: 4px;
      box-shadow: 0px 0px 0px 1px $brand-color, 0 0px 0px 1px $brand-color;
      transform: scale(0.6);

      @media (min-width: 1024px) {
        transform: scale(0.8);
      }

      @media (min-width: 1280px) {
        transform: scale(1);
      }

      &::after {
        content: '';
        position: absolute;
        width: 0;
        height: 0;
        bottom: -11px;
        right: 6px;
        border: 12px solid transparent;
        border-top-color: #202020;
        border-bottom: none;
        filter: drop-shadow(0 1px 0px $brand-color);
        transform: scaleX(-0.7);
      }

      &.uk {
        top: 9%;
        right: 51%;
      }

      &.nl {
        top: 1%;
        right: 40%;

        &::after {
          right: 70px;
        }
      }

      &.de {
        top: 3%;
        right: 30%;

        &::after {
          right: 47px;
        }
      }

      &.ch {
        top: 25%;
        right: 42%;
        box-shadow: 0px 0px 0px 1px $brand-color, 0 0px 0px 1px $brand-color;

        &::after {
          right: 30px;
          bottom: 42px;
          border-bottom: 12px solid;
          border-bottom-color: #202020;
          border-top: none;
          filter: drop-shadow(0 -1px 0px $brand-color);
        }
      }

      &.hk {
        top: 37%;
        right: 12%;

        &::after {
          right: 55px;
        }
      }

      &.jp {
        top: 28%;
        right: 4%;

        &::after {
          right: 28px;
        }
      }

      &.sg {
        top: 59%;
        right: 14%;
        box-shadow: 0px 0px 0px 1px $brand-color, 0 0px 0px 1px $brand-color;

        &::after {
          right: 51px;
          bottom: 24px;
          border-bottom: 12px solid;
          border-bottom-color: #202020;
          border-top: none;
          filter: drop-shadow(0 -1px 0px $brand-color);
        }
      }

      &.ny {
        top: 21%;
        right: 65%;

        &::after {
          right: 50px;
        }
      }

      &.la {
        top: 34%;
        left: 6%;

        &::after {
          right: 10px;
        }
      }

      &.jb {
        bottom: 29%;
        right: 40%;
      }

      &.chicago {
        top: 25%;
        left: 15%;

        &::after {
          right: 20px;
        }
      }

      &.sao-paulo {
        bottom: 24.5%;
        left: 24%;
      }

      &.mumbai {
        top: 33%;
        left: 68.5%;
      }

      &.telaviv {
        top: 22%;
        right: 36%;
        box-shadow: 0px 0px 0px 1px $brand-color, 0 0px 0px 1px $brand-color;

        &::after {
          border-bottom: 12px solid #202020;
          border-width: 9px;
          border-top: none;
          bottom: -9px;
          filter: drop-shadow(0 -1px 0 $brand-color);
          right: 36px;
          transform: rotate(180deg);
        }
      }

      &.dubai {
        top: 28%;
        right: 32%;
        box-shadow: 0 0 0 1px $brand-color, 0 0px 0px 1px $brand-color;

        &::after {
          right: 29px;
          bottom: -9px;
          border-bottom: 9px solid #202020;
          border-width: 9px;
          border-top: none;
          filter: drop-shadow(0 -1px 0px $brand-color);
          transform: rotate(180deg);
        }
      }

      &.miami {
        top: 43%;
        left: 20%;
        box-shadow: 0px 0px 0px 1px $brand-color, 0 0px 0px 1px $brand-color;

        @media (min-width: 1024px) {
          top: 46%;
          left: 22%;
        }

        &::after {
          right: unset;
          left: 10px;
          bottom: 24px;
          border-bottom: 12px solid;
          border-bottom-color: #202020;
          border-top: none;
          filter: drop-shadow(0 -1px 0px $brand-color);
        }
      }

      &.washington {
        top: 35%;
        left: 25%;
        box-shadow: 0px 0px 0px 1px $brand-color, 0 0px 0px 1px $brand-color;

        &::after {
          right: unset;
          left: 10px;
          bottom: 24px;
          border-bottom: 12px solid;
          border-bottom-color: #202020;
          border-top: none;
          filter: drop-shadow(0 -1px 0px $brand-color);
        }
      }

      &.toronto {
        top: 9%;
        right: 74%;

        &::after {
          right: 10px;
        }
      }

      &.paris {
        top: 26%;
        right: 50%;

        &::after {
          right: unset;
          left: 10px;
          bottom: 24px;
          border-bottom: 12px solid;
          border-bottom-color: #202020;
          border-top: none;
          filter: drop-shadow(0 -1px 0px $brand-color);
        }
      }

      &.sydney {
        top: 76%;
        right: -1%;
      }
      &.seoul {
        top: 24%;
        right: 13%;
      }

      &__city {
        font-weight: 600;
        font-size: 12px;
      }

      &__country {
        font-weight: 300;
        font-size: 12px;
      }
    }
  }
}

.dc-locations-mobile {
  // position: absolute;
  // bottom: 0;
  // width: 100%;

  @media (min-width: 768px) {
    display: none;
  }

  &__slider {
  }

  .splide {
    &.is-initialized:not(.is-active) .splide__list {
      display: flex;
    }

    &__pagination {
      position: absolute;
      z-index: 1;
      bottom: 12px;
      left: 12px;
      flex-wrap: wrap;
      justify-content: flex-start;
    }

    &__pagination__page {
      padding: 9.5px;
      display: inline-block;
      width: 8px;
      height: 8px;
      background: rgb(#fff, 0.01);
      background-clip: content-box;
      border-radius: 50%;
      box-sizing: content-box;
      transition: transform 0.2s linear;
      border: none;

      @media (min-width: 414px) {
        padding: 12px;
      }

      &::after {
        content: '';
        display: block;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border: 1px solid #fff;
        border-radius: 50%;
      }

      &.is-active {
        background-color: $brand-color;

        &::after {
          border-color: transparent;
        }
      }
    }

    &__track {
      // width: 100%;
      // max-width: 400px;
      // margin: responsive-rem(13) auto 0;
    }

    &__slide {
      // padding: 0;
    }

    @media (min-width: 768px) {
      padding-top: 0;

      &__track {
        // width: 450px;
        // max-width: unset;
        // margin: 70px auto 0;
      }

      &__slide {
        // padding: 0 80px 0 40px;
      }
    }
  }

  .dc-locations-slide {
    &::after {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background: linear-gradient(
        180deg,
        $black1-color 0.07%,
        rgba(0, 0, 0, 0) 99.93%
      );
    }

    &__img {
      max-height: 369px;
      object-fit: cover;
      object-position: center;
      width: 100%;
      height: 100%;
    }

    &__inner {
      height: 50px;
      position: absolute;
      left: 24px;
      bottom: 64px;
      z-index: 2;
    }

    &__city {
      font-size: 24px;
      font-weight: 700;
      line-height: 1;
    }

    &__country {
      margin-top: 4px;
      display: inline-block;
      font-weight: 500;
    }
  }
}
