.pricing-card {
  $this: &;

  position: relative;
  width: 70vw;
  // min-height: 55vh;
  min-width: 248px;
  max-width: 300px;
  display: flex;
  flex-direction: column;

  &.special {
    margin-right: 0 !important;

    .pricing-card__icon {
      svg {
        filter: drop-shadow(0px 4px 20px rgba(255, 255, 255, 0.8));
      }
    }

    .pricing-card__params {
      .pricing-card__param:nth-child(2) {
        .text-branded {
          color: #f5ea00;
        }
      }
    }
  }

  &__inner {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 8px 24px 16px;
    background: $card-xray-color;
    border: 1px solid #202020;
    border-radius: 8px;
    justify-content: space-around;
  }

  &__icon {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-basis: 110px;

    svg {
      margin: auto 0;
      filter: drop-shadow(0px 4px 24px rgba(8, 190, 11, 0.5));
    }
  }

  &__title {
    margin-top: 8px;
    font-size: 24px;
    font-weight: 700;
    line-height: 1;
    text-align: center;
  }

  &__badge {
    display: inline-block;
    padding: 6px 10px;
    position: absolute;
    top: 0;
    right: 0;
    background: #4de450;
    font-weight: 700;
    color: #202020;
    border-radius: 0 8px;
  }

  &__params {
    margin-top: 16px;
    font-size: 14px;
    font-weight: 600;
    text-align: center;
  }

  &__divider {
    width: 100%;
    margin: 18px 20px;
    height: 1px;
    background-color: rgba(255, 255, 255, 0.20);
    border: 0;
  }

  &__discount {
    margin: 12px 0;
    color: #8F8F8F;
    span {
      color: white;
      background-color: rgba(255, 255, 255, 0.20);
      padding: 4px 12px;
      font-weight: 600;
      margin-left: 8px;
    }
    &-cycle {
      display: none;
      &.show {
        display: block;
      }
    }
  }


  &__param:not(:first-child) {
    margin-top: 8px;
  }

  &__price {
    width: 100%;
  }

  .btn {
    margin-top: 16px;
    width: 100%;
  }

  @media (min-width: 768px) {
    width: 27vw;

    &__inner {
      // justify-content: ;
    }

    &__icon {
      // height: 70px;
    }

    &__title {
      font-size: 30px;
    }

    &__params {
      margin-top: 24px;
      font-size: 16px;
    }

    &__param:not(:first-child) {
      margin-top: 12px;
    }

    .btn {
      margin-top: 16px;
      width: 100%;
    }
  }

  @media (min-width: 1280px) {
    width: calc(25% - 35px);
  }

  &.popular {
    #{$this}__inner {
      background: $brand-xray-color;
      border-color: $brand-color;
    }
  }
}

.price-row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  text-align: center;
  gap: 8px;

  &__value {
    font-size: 30px;
    line-height: 1.2;
    font-weight: 700;
    color: $brand-color;
    &__decimal {
      font-size: 13px;
    }
  }

  &__cycle {
    display: block;
    font-size: 12px;
    font-weight: 600;
    color: $brand-color;
    text-align: center;
  }

  &__msg {
    display: none;
    font-size: 12px;
    color: $brand-color;
    text-align: center;
    &.show {
      display: block;
    }
  }

  &__months-free {
    padding-top: 10px;
    color: #fff;
    font-size: 12px!important;
  }

  &__slashed {
    font-size: 24px;
    font-weight: 400;
    text-align: center;
    text-decoration: line-through;
    color: $faded-text-color;
  }

  &__price-cycle {
    display: none;
    &.show {
      display: block;
    }
  }

  &__sufix {
    padding-top: 12px;
    font-weight: 600;
    line-height: 27px;
    color: $faded-text-color;
  }

  @media (min-width: 768px) {
    flex-direction: row;
    // flex-wrap: wrap;

    &__value {
      font-size: 36px;
    }

    &__cycle {
      font-size: 14px;
      font-weight: 600;
      color: $brand-color;
    }

    &__msg {
      font-size: 14px;
    }

    &__slashed {
      padding-top: 6px;
      font-size: 24px;
      font-weight: 400;
      text-align: center;
      text-decoration: line-through;
      color: $faded-text-color;
      line-height: 37px;
    }

    &__sufix {
      padding-top: 16px;
    }
  }
}
