.trust-banner {
  background: #121212;
  padding: 24px 0;

  @media (min-width: 576px) {
    padding: 80px 0 40px 0;
  }

  &__container {
    display: flex;
    justify-content: center;
  }

  &__content {
    display: flex;
    justify-content: center;
  }

  &__grid {
    display: flex;
    justify-content: space-between;
  }

  &__item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  &__icon {
    position: relative;

    svg {
      position: absolute;
      width: 24px;
      height: 24px;
      left: -33px;
      top: -6px;

      @media (min-width: 768px) {
        width: 44px;
        height: 44px;
        left: -71px;
        top: -17px;
      }

      @media (min-width: 1020px) {
        width: 50px;
        height: 50px;
        left: -71px;
        top: -17px;
      }
    }
  }

  &__icon--3 svg {
    left: -37px;

    @media (min-width: 768px) {
      left: -75px;
    }
  }

  &__icon--4 svg {
    left: -45px;

    @media (min-width: 768px) {
      left: -85px;
    }
  }

  &__counter {
    color: #4DE450;
    text-align: center;
    font-size: 25.337px;
    font-weight: 800;

    @media (min-width: 576px) {
      font-size: 36px;
    }

    @media (min-width: 768px) {
      font-size: 44px;
    }

    @media (min-width: 1020px) {
      font-size: 50px;
    }
  }

  &__text {
    color: #FFF;
    text-align: center;
    font-size: 12px;
    font-weight: 400;
    line-height: 140%; /* 30.8px */
    text-transform: capitalize;

    @media (min-width: 768px) {
      font-size: 18px;
    }
  }
}
