.testimonials-section {
  .fp-bg {
    background: url('/img/testimonials/mobile-bg@2x.png') no-repeat;
    background-size: 100% 150px;
    background-position: left bottom;

    @media (min-width: 1024px) {
      background: url('/img/testimonials/bg.svg') no-repeat;
      // background-size: contain;
      background-position: center 120%;

      @include ultrawide-bg-fix(false);
    }

    @media (min-width: 1280px) {
      background-position: center 120%;
    }
  }
}

.testimonials {
  padding-bottom: 200px;

  @media (min-width: 1024px) {
    padding-bottom: 220px;
  }

  &__content {
    @include responsive-grid-column(0, 1 / span 12, 2 / span 6);

    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__title {
    text-align: center;
  }

  &__text {
    margin-top: 16px;
  }

  &__trustpilot {
    margin:24px;

    svg {
      max-width: 203px;

      @media (min-width: 768px) {
        max-width: unset;
      }
    }
  }

  &__see {
    display: block;
    font-size: 14px;
    font-weight: 500;
    text-align: center;
    text-decoration: none;

    @media (min-width: 768px) {
      font-size: 16px;
    }
  }

  .splide {
    width: 100%;
    max-width: 100%;

    .vimeo-player {
      flex: none;
      position: relative;

      &::before {
        padding-top: 0;
      }

      &.is-prev {
        display: block;

        &::after {
          content: '';
          display: block;
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          z-index: 2;
        }
      }

      &__inner {
        position: relative;
        height: 100%;
        min-height: 530px;

        img {
          position: relative;
        }

        iframe {
          height: 100%;
        }
      }
    }

    &__arrows {
      margin-top: 32px;
      position: absolute;
      width: 100%;
      display: flex;
      justify-content: space-between;
      max-width: 110px;
      bottom: -60px;
      left: 50%;
      transform: translate(-50%, 0);
    }
  }

  .testimonial {
    display: flex;
    flex-direction: column;
    align-items: center;

    @media (min-width: 768px) {
      justify-content: center;
    }

    &__title {
      font-size: 16px;
      font-weight: 700;
    }

    &__text {
      margin-top: 8px;
      text-align: center;
    }

    &__author {
      margin-top: 24px;
      text-transform: uppercase;
      color: $brand-color;
    }
  }

  .testimonials__videos {
    @include responsive-grid-column(0, 1 / span 12);
    display: flex;
    flex-direction: column;
    margin-bottom: 32px;
    gap: 24px;
    width: 100%;

    @media (min-width: 768px) {
      min-height: 500px;
      flex-direction: row;
    }
  }

  .vimeo-player {
    flex: 1 1 500px;
  }

  .testimonials__extra-btn {
    margin-top: 80px;
  }
}
