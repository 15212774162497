.broker-solutions-section {
  .grid-wrap {
    justify-content: end;

    @media (min-width: 768px) {
      justify-content: center;
    }
  }

  .fp-bg {
    background: url('/img/broker-solutions/mobile-bg@2x.jpg') no-repeat;
    background-position: center top;
    background-size: contain;

    @media (min-width: 768px) {
      background: $black3-color url('/img/broker-solutions/bg@2x.jpg') no-repeat;
      background-position: right top;
      background-size: contain;

      @include ultrawide-bg-fix;
      @include bg-filter(
        linear-gradient(
          270deg,
          rgba(2, 6, 7, 0) 37.6%,
          rgba(2, 6, 7, 0.74) 54.19%,
          #020607 70.22%,
          #020607 100%
        )
      );
    }
  }
}

.broker-solutions {
  padding-top: 60vw;

  @media (min-width: 768px) {
    padding-top: 0;
  }

  &__content {
    @include responsive-grid-column();

    display: flex;
    flex-direction: column-reverse;

    @media (min-width: 768px) {
      flex-direction: row;
      justify-content: space-between;
      position: relative;
    }
  }

  &__quote {
    align-self: center;
    margin-top: 36px;
    display: flex;
    flex-direction: column;
    align-items: center;

    @media (min-width: 768px) {
      margin-top: 0;
      display: block;
      position: absolute;
    }
  }

  &__text {
    text-align: center;
    font-size: 14px;

    &::before {
      content: '';
      display: block;
      width: 150px;
      height: 26px;
      margin: 0 auto 10px;

      @include sprite('jens-star');
      background-repeat: space;
    }

    @media (min-width: 768px) {
      text-align: left;
      font-size: 18px;
      max-width: 600px;

      &::before {
        margin: 0 0 10px;
      }
    }
  }

  &__cta {
    margin-top: 24px;
    padding-left: 70px;
    padding-right: 70px;
  }

  .broker-person {
    height: auto;
    align-items: center;
    justify-content: center;

    display: flex;
    flex-direction: column;
    text-align: center;

    @media (min-width: 768px) {
      width: 100%;
      height: 80vh;
      max-height: 900px;
      align-items: flex-end;
      justify-content: flex-end;
      text-align: right;
    }

    &__name {
      font-size: 24px;
      font-weight: 500;
      color: $brand-color;
    }

    &__logo {
      margin-bottom: 24px;
      width: 190px;

      @media (min-width: 768px) {
        margin-bottom: 16px;
        width: 280px;
      }
    }
  }
}
