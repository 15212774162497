@import 'base';
@import 'blocks/vps-pricing-new';
@import 'blocks/dc-locations';
@import 'blocks/testimonials';
@import 'blocks/broker-solutions';
@import 'blocks/faq';
@import 'blocks/footer';
@import 'blocks/trust-banner';

// hero

.home-hero-section,
.home-logged-hero-section {
  .pwrd-by-equinix {
    display: inline-grid;
    font-size: 8px;
    font-weight: 500;

    @media (min-width: 1025px) {
      font-size: 12px;
    }

    &__img {
      margin-top: 7px;
      width: 110px;
      height: 20px;

      @media (min-width: 1025px) {
        width: 178px;
        height: 30px;
      }
    }
  }

  @include trustpilot-widget-mini;
}

.home-logged-hero-section {
  .fp-bg {
    background: $black2-color url('/img/home-logged-hero/desktop-bg@2x.jpg')
      no-repeat;
    background-position: right top;
    background-size: cover;

    @include ultrawide-bg-fix;
    @include bg-filter(
      linear-gradient(
        89.79deg,
        #000000 23.68%,
        rgba(0, 0, 0, 0.89) 55.09%,
        rgba(0, 0, 0, 0) 98.77%
      )
    );
  }

  .hero-content {
    .simple-list {
      &__item {
        &::before {
          pointer-events: none;
        }

        &:hover {
          &::before {
            @include sprite(
              'rightarrow',
              (
                color: $brand-color,
              )
            );
          }
        }
      }

      a {
        color: #fff;

        @include hover-underline;

        &:hover {
          color: $brand-color;
        }
      }
    }
  }
}

.home-hero-section {
  @media (min-width: 600px) {
    min-height: 880px;
  }
  .fp-bg {
    .mobile-bg {
      position: relative;
      overflow: hidden;
      width: 100%;
      height: 100%;
      background: url('/img/home-hero/mobile-bg@2x.png') no-repeat;
      background-position: right top;
      background-size: 240px 282px;

      @include bg-filter(
        linear-gradient(360deg, $black1-color 20%, rgba(0, 0, 0, 0) 51.3%)
      );

      &::after {
        width: 240px;
        height: 282px;
        top: unset;
        left: unset;
        right: 0;
      }

      @media (max-width: 1023px) and (min-height: 768px) {
        transform-origin: bottom right;
        transform: scale(1.4);
      }

      @media (min-width: 1024px) {
        display: none;
      }

      .beam {
        $beam-a-speed: random(3) + 1s;
        $beam-a-delay: random(3000) + 0ms;

        &.a {
          height: 3px;
          transform: rotate(315.5deg);
          width: 100px;
          top: 214px;
          right: 0px;

          @include beam-fly($beam-a-speed, $beam-a-delay);
        }

        $beam-b-speed: random(3) + 1s;
        $beam-b-delay: random(3000) + 0ms;

        &.b {
          height: 2px;
          transform: rotate(134deg);
          width: 160px;
          top: 284px;
          right: 110px;

          @include beam-fly($beam-b-speed, $beam-b-delay);
        }

        $beam-c-speed: random(3) + 1s;
        $beam-c-delay: random(3000) + 0ms;

        &.c {
          height: 1px;
          transform: rotate(313.5deg);
          width: 215px;
          top: 134px;
          right: 0px;

          @include beam-fly($beam-c-speed, $beam-c-delay);
        }

        $beam-d-speed: random(3) + 1s;
        $beam-d-delay: random(3000) + 0ms;

        &.d {
          height: 1px;
          transform: rotate(312.5deg);
          width: 250px;
          top: 103px;
          right: 0px;

          @include beam-fly($beam-d-speed, $beam-d-delay);
        }

        $beam-e-speed: random(3) + 1s;
        $beam-e-delay: random(3000) + 0ms;

        &.e {
          height: 0.5px;
          transform: rotate(132deg);
          width: 285px;
          top: 284px;
          right: 186px;

          @include beam-fly($beam-e-speed, $beam-e-delay);
        }
      }
    }

    .desktop-bg {
      position: relative;
      overflow: hidden;
      display: none;
      width: 100%;
      height: 100%;
      background: url('/img/home-hero/desktop-bg@2x.jpg') no-repeat;
      background-position: top right;
      background-size: 900px;

      @include ultrawide-bg-fix;
      @include bg-filter(
        linear-gradient(
          270deg,
          rgba(2, 6, 7, 0) 10.6%,
          rgba(2, 6, 7, 0.74) 54.19%,
          #020607 70.22%,
          #020607 100%
        )
      );
      &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        z-index: 2;
        display: block;
        width: 100%;
        height: 100%;
        background: linear-gradient(180deg, rgba(2, 6, 7, 0) 10%, rgba(2, 6, 7, 0.74) 55%, #020607 100%, #020607 100%);
      }

      @media (min-width: 1024px) {
        display: block;
      }

      @media (min-width: 1024px) and (min-height: 1200px) {
        transform-origin: top left;
        transform: scale(1.4) translateX(-50%);
      }

      .beam {
        $beam-a-speed: random(3) + 1s;
        $beam-a-delay: random(3000) + 0ms;

        &.a-left,
        &.a-right {
          height: 15px;

          &::after {
            height: 3px;
          }
        }

        &.a-left {
          transform: rotate(-37deg);
          width: 400px;
          top: 504px;
          right: 396px;

          @include beam-fly($beam-a-speed, $beam-a-delay);
        }

        &.a-right {
          transform: rotate(41deg);
          width: 396px;
          top: 763px;
          right: 96px;

          @include beam-fly($beam-a-speed, $beam-a-delay, true);
        }

        $beam-b-speed: random(3) + 1s;
        $beam-b-delay: random(3000) + 0ms;

        &.b-left {
          transform: rotate(139deg);
          width: 470px;
          top: 679px;
          right: 752px;

          @include beam-fly($beam-b-speed, $beam-b-delay, true);
        }

        &.b-right {
          transform: rotate(224.5deg);
          width: 500px;
          top: 369px;
          right: 400px;

          @include beam-fly($beam-b-speed, $beam-b-delay);
        }

        $beam-c-speed: random(3) + 1.5s;
        $beam-c-delay: random(2000) + 1000ms;

        &.c-left {
          transform: rotate(317deg);
          width: 582px;
          top: 272px;
          right: 404px;

          @include beam-fly($beam-c-speed, $beam-c-delay);
        }

        &.c-right {
          transform: rotate(46.1deg);
          width: 582px;
          top: 691px;
          right: 0px;

          @include beam-fly($beam-c-speed, $beam-c-delay, true);
        }

        $beam-d-speed: random(3) + 0.5s;
        $beam-d-delay: random(2000) + 1000ms;

        &.d-left {
          transform: rotate(135.3deg);
          width: 582px;
          top: 615px;
          right: 818px;

          @include beam-fly($beam-d-speed, $beam-d-delay, true);
        }

        &.d-right {
          transform: rotate(227.5deg);
          width: 582px;
          top: 206px;
          right: 406px;

          @include beam-fly($beam-d-speed, $beam-d-delay);
        }
      }
    }
  }

  &__video-bg {
    position: absolute;
    top: 0;
    opacity: .2;
    height: 100%;
    width: 100%;
    object-fit: cover;
  }

  .hero-content {
    @media (max-width: 1023px) {
      padding-top: 0;
    }
    &__cta {
      .btn {
        min-width: fit-content;
        max-width: 400px;
      }
    }

    &__list {
      @media (min-width: 1024px) {
        column-count: 2;
      }
    }

    &__text {
      color: $faded-text-color;
    }
    .hero-content__widgets {
      display: flex;
      align-items: center;
      margin-top: 24px;
      width: 100%;
      min-width: 250px;
      gap: 16px;
      flex-wrap: wrap;
      @media (min-width: 768px) {
        margin-top: 32px;
        width: 85%;
      }
    }
  }

  .uptime-guarantee-tick {
    border-radius: 4px;
    background: rgba(22, 48, 22, 0.78);
    padding: 3px;
  }
  @keyframes shimmer {
    0% {
      fill: #4DE450;
    }
    50% {
      fill: #A8F5A2;
    }
    100% {
      fill: #4DE450;
    }
  }
  .status-chart.shimmer {
    animation: shimmer 1s ease-in-out forwards;
  }
}

.how-it-works-section {
  border-radius: 8px;
  border: 1px solid rgba(255, 255, 255, 0.20);
  background: #151515;
  margin-left: 16px;
  margin-right: 16px;
  @media (min-width: 1024px) {
    border-radius: 0;
    border: none;
    background: transparent;
    margin-left: 0;
    margin-right: 0;
  }
  .how-it-works {
    &__container {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      position: relative;
      gap: 75px 8px;
      @media (min-width: 1024px) {
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: 150px 1fr;
        gap: 0;
      }
      .how-it-works-monitor {
        position: relative;
        display: flex;
        &.show {
          &::after,
          &::before {
            max-height: 46%;
          }
        }
        &::after,
        &::before {
          content: '';
          width: 94%;
          position: absolute;
          height: 100%;
          background: #000;
          transition: 0.5s;
          overflow: hidden;
          max-height: 0;
        }
        &::after {
          top: 3%;
          transform-origin: center top;
          left: 50%;
          transform: translateX(-50%);
          @media (min-width: 1024px) {
            top: 3%;
          }
        }
        &::before {
          bottom: 12%;
          transform-origin: center bottom;
          left: 50%;
          transform: translateX(-50%);
          @media (min-width: 1024px) {
            bottom: 30%;
          }
        }
      }
      .monitor-status-light {
        width: 5px;
        height: 5px;
        background-color: $brand-color;
        position: absolute;
        bottom: 9%;
        right: 13px;
        border-radius: 50%;
        @media (min-width: 1024px) {
          bottom: 26%;
        }
        &.show {
          background-color: red;
        }
      }
      .pc-vps-signal,
      .vps-broker-signal {
        @include how-it-works-signal-vertical;
        max-height: 10%;
        width: 3px;
        height: 260px;
        top: 65%;
        margin: 28px auto 0;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        z-index: -1;
        position: absolute;
        @media (min-width: 1024px) {
          @include how-it-works-signal;
          max-width: 100%;
          height: 3px;
          top: 65%;
          width: 100%;
        }
        &::before {
          content: '';
          display: block;
          width: 100%;
          height: 50px;
          animation-name: how-it-works-signal-vertical;
          animation-duration: 1.1s;
          animation-iteration-count: infinite;
          animation-timing-function: ease-in-out;
          @media (min-width: 1024px) {
            animation-name: how-it-works-signal;
            height: 1px;
            width: 100px;
          }
        }
      }
      .pc-vps-signal {
        left: 50%;
        top: 100%;
        @media (min-width: 1024px) {
          left: 50%;
          top: 40%;
        }
        &.hidden {
          opacity: 0 !important;
        }
      }
      .vps-broker-signal {
        left: 50%;
        top: 100%;
        @media (min-width: 1024px) {
          left: 50%;
          top: 40%;
        }
      }
    }
    &__item {
      &__text {
        display: flex;
        flex-direction: column;
        gap: 8px;
        align-self: center;
        @media (min-width: 1024px) {
          align-self: flex-start;
          justify-content: center;
        }
        &:nth-child(1) { // Your PC
          order: 2;
          @media (min-width: 1024px) {
            order: 1;
          }
        }
        &:nth-child(2) { // Forex VPS
          order: 4;
          @media (min-width: 1024px) {
            order: 2;
          }
        }
        &:nth-child(3) { // Your Broker
          order: 6;
          @media (min-width: 1024px) {
            order: 3;
          }
        }
      }
      &__image {
        display: flex;
        align-items: flex-end;
        justify-content: center;
        position: relative;
        z-index: 2;
        @media (min-width: 1024px) {
          padding: 0 10%;
          &:not(.monitor) {
            padding: 0 25%;
            &::after {
              position: absolute;
              bottom: -10px;
              left: 50%;
              transform: translateX(-50%);
              background: radial-gradient(83.11% 44.21% at 45.26% 0%, #4DE450 0%, rgba(77, 228, 80, 0) 100%);
              filter: blur(20px);
              width: 170px;
              height: 120px;
              border-radius: 25%;
              z-index: -1;
              @media (min-width: 1024px) {
                content: "";
                bottom: -80px;
              }
            }
          }
        }
        &.monitor {
          position: relative;
          @media (min-width: 1024px) {
            padding-bottom: 85px;
          }
          &::after {
            content: "";
            position: absolute;
            bottom: -10px;
            left: 50%;
            transform: translateX(-50%);
            background: radial-gradient(83.11% 44.21% at 45.26% 0%, #4DE450 0%, rgba(77, 228, 80, 0) 100%);
            filter: blur(20px);
            width: 170px;
            height: 120px;
            border-radius: 25%;
            z-index: -1;
            @media (min-width: 1024px) {
              bottom: -55px;
            }
          }
        }
        &:nth-child(4) { // Your PC
          order: 1;
          @media (min-width: 1024px) {
            order: 4;
          }
        }
        &:nth-child(5) { // Forex VPS
          order: 3;
          @media (min-width: 1024px) {
            order: 5;
          }
        }
        &:nth-child(6) { // Your Broker
          order: 5;
          @media (min-width: 1024px) {
            order: 6;
          }
        }
      }
    }
    &__heading {
      display: block;
      color: #FFF;
      text-align: center;
      font-size: 24px;
      font-weight: 800;
      text-transform: uppercase;
      @media (min-width: 1024px) {
        font-size: 30px;
      }
    }
    &__text {
      display: flex;
      gap: 5px;
      align-items: center;
      margin: 0 auto;
      color: #FFF;
      text-align: center;
      font-size: 12px;
      font-weight: 500;
      border-radius: 17px;
      background: #1D1D1D;
      padding: 8px 16px;
    }
  }
}

.tp-reviews-grid-section {
  padding-bottom: 350px;
  @media (min-width: 1200px) {
    padding-bottom: 430px;
  }
  .fp-bg {
    bottom: 100px;
    background: url('/img/testimonials/mobile-bg@2x.png') no-repeat;
    background-size: 100% 150px;
    background-position: left bottom;

    @media (min-width: 1024px) {
      background: url('/img/testimonials/bg.svg') no-repeat;
      // background-size: contain;
      background-position: center 120%;

      @include ultrawide-bg-fix(false);
    }

    @media (min-width: 1280px) {
      background-position: center 120%;
    }
  }
}

.optimised-trading-platforms-section {
  @include tech-ribbon;
  .bottom-ribbon {
    position: relative;
    bottom: 0;
    .logo-slider {
      opacity: 1;
      @media (min-width: 1024px) {
        opacity: 1;
      }
    }
  }
  .fp-bg {
    background: url('/img/homepage/trading-platforms-bg.png') no-repeat;
    background-size: cover;
    background-position: 50% 50%;
  }
}
.supported-by-brokers-section {
  @include broker-ribbon;
  .bottom-ribbon {
    position: relative;
    bottom: 0;
    .logo-slider {
      opacity: 1;
      @media (min-width: 1024px) {
        opacity: 1;
      }
    }
  }
  .fp-bg {
    background: url('/img/homepage/brokers-bg.png') no-repeat;
    background-size: cover;
    background-position: 50% 50%;
  }
}

.never-overload-servers-section {
  .flex {
    gap: 30px;
  }
  .flex-block-40:last-child {
    display: none;
    @media (min-width: 1024px) {
      display: block;
    }
  }
  .never-overload-servers-image.generic {
    position: relative;
    .generic-overlay {
      position: absolute;
      top: 0;
      left: 50%;
      width: 80%;
      transform: scaleY(1) translateX(-50%);
      transform-origin: 50% 20%;
    }
    .generic-lights {
      position: absolute;
      top: 0;
      left: 50%;
      width: 80%;
      height: 100%;
      transform: translateX(-50%);
      object-fit: contain;
      object-position: center top;
      // Define a list of variations for durations and delays
      $durations: 1.5s, 2s, 2.5s;
      $delays: 0s, 0.3s, 0.5s, 0.7s, 1s, 1.3s, 1.5s;

      // Define multiple similar animations with subtle variations
      @keyframes pulse1 {
        0% { opacity: 0.4; }
        50% { opacity: 1; }
        100% { opacity: 0.4; }
      }

      @keyframes pulse2 {
        0% { opacity: 0.5; }
        50% { opacity: 1; }
        100% { opacity: 0.5; }
      }

      @keyframes pulse3 {
        0% { opacity: 0.3; }
        50% { opacity: 1; }
        100% { opacity: 0.3; }
      }

      @keyframes pulse4 {
        0% { opacity: 0.6; }
        50% { opacity: 1; }
        100% { opacity: 0.6; }
      }

      // Assign animations in a pattern to the generic-server rects
      .generic-server {
        animation-iteration-count: infinite;
        animation-timing-function: ease-in-out;

        // Use nth-of-type selectors to vary animation, duration, and delay
        &:nth-of-type(1) {
          animation-name: pulse1;
          animation-duration: nth($durations, 1);
          animation-delay: nth($delays, 1);
        }

        &:nth-of-type(2) {
          animation-name: pulse2;
          animation-duration: nth($durations, 2);
          animation-delay: nth($delays, 2);
        }

        &:nth-of-type(3) {
          animation-name: pulse3;
          animation-duration: nth($durations, 3);
          animation-delay: nth($delays, 3);
        }

        &:nth-of-type(4) {
          animation-name: pulse4;
          animation-duration: nth($durations, 1);
          animation-delay: nth($delays, 4);
        }

        &:nth-of-type(5) {
          animation-name: pulse2;
          animation-duration: nth($durations, 2);
          animation-delay: nth($delays, 5);
        }
      }

    }
    .server-img {
      width: 80%;
      background-position: center top;
      background-size: contain;
      margin: 0 auto;
      display: block;
    }
    &.active {
      .generic-overlay {
        transform: scaleY(1.02) translateX(-50%);
      }
    }
  }
  .never-overload-servers-image.forexvps {
    position: relative;
    .forexvps-overlay {
      position: absolute;
      top: 0;
      left: 50%;
      width: 80%;
      transform: scaleY(1) translateX(-50%);
      transform-origin: 50% 73%;
    }
    .forexvps-lights {
      position: absolute;
      top: 0;
      left: 50%;
      width: 80%;
      height: 100%;
      transform: translateX(-50%);
      object-fit: contain;
      object-position: center top;
      $delays: 0s, 0.3s, 0.6s, 0.9s, 1.2s, 1.5s, 1.8s, 2.1s;
      .server-on {
        filter: url(#green-glow);
        animation-name: glowPulse;
        animation-duration: 2s;
        animation-iteration-count: infinite;
      }

      @keyframes glowPulse {
        0%   { opacity: 0.7; }
        50%  { opacity: 1;   }
        100% { opacity: 0.7; }
      }

      // Generate classes for each delay value
      @for $i from 1 through length($delays) {
        .status-light-#{$i} {
          animation-delay: nth($delays, $i);
        }
      }
    }
    .server-img {
      width: 80%;
      background-position: center top;
      background-size: contain;
      margin: 0 auto;
      display: block;
    }
    &.active {
      .forexvps-overlay {
        transform: scaleY(1.02) translateX(-50%);
      }
    }
  }
  .never-overload-servers-image {
    .fvps-load,
    .generic-load {
      position: absolute;
    }
    .fvps-load {
      top: 32%;
      left: 92%;
    }
    .generic-load {
      top: 0%;
      left: -9%;
    }
    &.generic {
      .server-type {
        position: relative;
        right: 75px;
      }
    }
    &.forexvps {
      .server-type {
        position: relative;
        left: 17%;
      }
    }
    .server-type {
      font-size: 24px;
      font-weight: 700;
    }
  }
}

.optimising-latency-section {
  .background {
    position: relative;
    z-index: 0;
    background-position: center top;
    background-size: contain;
  }
  .cutout {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    z-index: 2;
  }
  .animated-arc-1,
  .animated-arc-2,
  .animated-arc-3 {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 534px;
  }
  .animated-arc-1__line {
    stroke-dasharray: 1350;
    stroke-dashoffset: 1350;
    animation: drawEraseLoop 4s ease-in-out infinite;
    filter: drop-shadow(0 0 6px limegreen);
  }
  .animated-arc-2__line {
    stroke-dasharray: 1350;
    stroke-dashoffset: 1350;
    animation: drawEraseLoop2 4s ease-in-out infinite;
    animation-delay: 2s;
    filter: drop-shadow(0 0 6px limegreen);
  }

  @keyframes drawEraseLoop {
    0%, 30% {
      stroke-dashoffset: 1350; // Hidden (start state)
    }
    50% {
      stroke-dashoffset: -1350; // Fully erased (moving left)
    }
    70%, 100% {
      stroke-dashoffset: 1350; // Erased to start state
    }
  }
  @keyframes drawEraseLoop2 {
    0%, 30% {
      stroke-dashoffset: 1350; // Hidden (start state)
    }
    50% {
      stroke-dashoffset: -1350; // Fully erased (moving left)
    }
    70%, 100% {
      stroke-dashoffset: 1350; // Erased to start state
    }
  }
}

.real-people-section {
  .fvps-container.overflow-hidden > .position-relative {
    &::before {
      content: '';
      width: 100%;
      height: 1px;
      position: absolute;
      bottom: 0;
      opacity: 0.5;
      background: linear-gradient(90deg, rgba(12,17,29,1) 0%, rgba(77,227,80,1) 50%, rgba(12,17,29,1) 100%);
    }
  }
  .support-team-animation {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 100%;
    height: 360px;
    margin: 0 auto 0;
    overflow: hidden;
    @media (min-width: 1024px) {
      width: 720px;
      height: 360px;
    }
    &::after {
      content: "";
      position: absolute;
      bottom: -85px;
      left: 50%;
      transform: translateX(-50%);
      background: radial-gradient(circle, #4de350 0%, #0c111d 100%);
      filter: blur(80px);
      width: 220px;
      height: 220px;
      border-radius: 50%;
      z-index: -1;
      opacity: 0.8;
    }
    .support-team-ring-inner {
      position: absolute;
      top: 30%;
      left: 50%;
      width: 300px;
      left: calc(50% - 150px);
      //transform: translate(-50%, 0);
      //animation: rotating 15s linear infinite;
    }
    .ripple-bg {
      width: 350px;
      height: 350px;
      position: absolute;
      top: 22%;
      left: 50%;
      transform: translate(-50%, 0);
      z-index: 1;

      $desktop-ripple-size: 350px;

      &__circles {
        width: $desktop-ripple-size;
        height: $desktop-ripple-size;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        opacity: 0.5;

        &::before,
        &::after {
          content: '';
          display: block;
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          border-radius: 50%;
          border: 1px solid $brand-color;
        }

        &::before {
          animation: ripple 5s linear infinite;
        }

        &::after {
          animation: ripple 5s linear 2.5s infinite;
        }
      }
    }
  }

  .support-team {
    position: absolute;
    transform-origin: center; // Ensure it rotates around the center point
    // Counteract the rotation of the parent
    //animation: counterRotate 15s linear infinite;
    display: flex;
    align-items: center;
    z-index: 2;
    &-cards {
      position: absolute;
      top: calc(90% - 150px);
      left: calc(50% - 150px);
      //animation: rotating 15s linear infinite;
      z-index: 2;
      width: 300px;
      height: 300px;
      @media (min-width: 1024px) {
        width: 500px;
        height: 500px;
        top: calc(100% - 250px);
        left: calc(50% - 250px);
      }
    }
    &__logo {
      position: relative;
      top: 75px;
      z-index: 0;
    }
    &__content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      border-radius: 8px;
      background: #17181B;
      align-self: center;
      font-size: 11px;
      width: 140px;
      overflow: hidden;
      height: 100%;
      max-height: 100px;
      transition: 0.3s;
      @media (min-width: 1024px) {
        font-size: 16px;
        width: 260px;
      }
      &__name {
        font-weight: 600;
        font-size: 9px;
        @media (min-width: 1024px) {
          font-size: 16px;
        }
      }
      &__role {
        color: #9AA4B2;
        font-weight: 400;
        font-size: 7px;
        @media (min-width: 1024px) {
          font-size: 14px;
        }
      }
    }
    &__image {
      position: relative;
      z-index: 2;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      @media (min-width: 1024px) {
        width: 80px;
        height: 80px;
      }
    }
    &.left {
      top: -60px;
      left: -45px;
      @media (min-width: 1024px) {
        left: -170px;
        top: 55px;
      }
      .support-team__content {
        padding: 3px 24px 3px 5px;
        border-radius: 4px 0 0 4px;
        @media (min-width: 1024px) {
          padding: 10px 24px 10px 16px;
          border-radius: 8px 0 0 8px;
        }
      }
      .support-team__image {
        right: 16px;
      }
    }
    &.right {
      top: 15px;
      right: -210px;
      @media (min-width: 1024px) {
        right: -265px;
        top: 125px;
      }
      &.desktop {
        display: none;
        @media (min-width: 1024px) {
          display: flex;
        }
      }
      .support-team__content {
        padding: 3px 5px 3px 24px;
        border-radius: 0 4px 4px 0;
        @media (min-width: 1024px) {
          padding: 10px 16px 10px 24px;
          border-radius: 0 8px 8px 0;
        }
      }
      .support-team__image {
        left: 16px;
      }
      &.mobile {
        display: flex;
        right: -55px;
        @media (min-width: 1024px) {
          display: none;
        }
        .support-team__content {
          padding: 3px 24px 3px 5px;
          border-radius: 4px 0 0 4px;
          @media (min-width: 1024px) {
            padding: 10px 24px 10px 16px;
            border-radius: 8px 0 0 8px;
          }
        }
        .support-team__image {
          left: -16px;
        }
      }
    }
    &.hidden {
      &.left,
      &.right {
        .support-team__content {
          max-height: 0;
          padding: 0;
        }
      }
    }
  }

  @keyframes rotating {
    from{
      transform: rotate(0deg);
    }
    to{
      transform: rotate(360deg);
    }
  }

  @keyframes counterRotate {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(-360deg);
    }
  }

  @keyframes ripple {
    0% {
      transform: scale(1);
      opacity: 0;
    }

    25% {
      opacity: 0.5;
    }

    75% {
      transform: scale(1.35);
      opacity: 1;
    }

    100% {
      transform: scale(1.5);
      opacity: 0;
    }
  }
}


.control-in-browser-section {
  .animated-window {
    &::before {
      content: none;
    }
  }
  .animated-click {
    $this: &;
    $mouseover-duration: 0.3s;
    $mouseout-duration: 0.15s;

    position: relative;
    padding: 40px 10px 24px;

    @media (min-width: 1024px) {
      padding: 40px 28px 24px;
    }

    transition: background-color 0.1s ease;

    &.success {
      background: rgb($brand-color, 0.1);
    }

    .animated-window-top {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
    }

    &__inner {
      height: 255px;
      display: flex;
      flex-direction: column;
      overflow: hidden;
      justify-content: space-around;
      align-items: center;
      font-size: 28px;
      font-weight: 600;
      text-align: center;
      &.hidden {
        display: none;
      }
    }

    .pointer {
      position: absolute;
      bottom: -25px;
      right: -10%;
      width: 13px;
      height: 22px;
      background-size: 100%;
      z-index: 2;

      @include svg-pointer;
      filter: drop-shadow(1px 1px 0px #245a28) drop-shadow(0px 1px 2px #000);

      @keyframes pointer-forth {
        100% {
          transform: translate(-70px, -15px);
        }
      }

      &.forth {
        // @include svg-pointer(#000);
        animation: pointer-forth $mouseover-duration ease-out forwards;
      }
    }

    @keyframes button-mouseover {
      0% {
        @include button-default-color;
      }

      100% {
        @include button-hover-color;
      }
    }

    @keyframes button-click {
      0% {
      }

      100% {
        transform: translateY(1px);
      }
    }

    &__btn-wrap {
      position: relative;
      margin: 0 auto;
    }

    .btn {
      width: 100%;
      max-width: 235px;
      margin: 0 auto;
      opacity: 0;
      transform: scale(0.8) translateY(200px);
      transition: transform 0.3s ease, opacity 0.3s ease;
      font-size: 16px;
      &.visible {
        opacity: 1;
        transform: scale(1) translateY(0);
      }

      &.mouseover {
        animation: button-mouseover $mouseover-duration ease-out forwards;
      }

      &.click {
        animation: button-click 0.1s ease-out forwards;

        @include button-hover-color;
        @include button-click;
      }
    }
  }
}

.price-you-expect-section {
  .trade-layout {
    display: flex;
    gap: 50px;
    overflow: hidden;
    padding: 160px 75px;
    justify-content: center;
    margin-bottom: 35px;
    @media (min-width: 1024px) {
      gap: 32px;
    }
  }

  .column {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .expected {
    display: flex;
    gap: 8px;
    color: #5080d4;
    position: relative;
  }
  .fast-trades {
    .values {
      left: 1px;
    }
    .expected {
      justify-content: flex-end;
      left: 2px;
      &-text {
        font-size: 12px;
        @media (min-width: 1024px) {
          font-size: 20px;
        }
      }
    }
    .expected.finished {
      color: $brand-color;
      border-color: transparent;
      .expected-text {
        display: none;
      }
      .success {
        display: flex;
        flex-direction: column;
        font-size: 12px;
        gap: 4px;
        position: absolute;
        left: -52px;
        top: -39px;
        @media (min-width: 1024px) {
          position: static;
          flex-direction: row;
          font-size: 16px;
          gap: 8px;
        }
      }
    }
    .highlight.finished {
      border-color: $brand-color;
      background: rgba(255,255,255,0.1);
    }
  }
  .slow-trades {
    .values {
      left: -1px;
    }
    .expected {
      justify-content: flex-start;
      right: 1px;
      &-text {
        font-size: 12px;
        @media (min-width: 1024px) {
          font-size: 20px;
        }
      }
    }
    .actual img {
      display: none;
    }
    .actual.finished {
      color: $form-error-color;
      border-color: transparent;
      display: flex;
      align-items: center;
      .success {
        display: flex;
        font-size: 12px;
        position: absolute;
        top: 8px;
        right: -71px;
        @media (min-width: 1024px) {
          position: relative;
          font-size: 16px;
          top: 1px;
          right: -3px;
        }
      }
      img {
        position: relative;
        top: -8px;

        &.mob-arrow {
          display: block;
          @media (min-width: 1024px) {
            display: none;
          }
        }
        &.desktop-arrow {
          display: none;
          @media (min-width: 1024px) {
            display: block;
          }
        }
        @media (min-width: 1024px) {
          position: static;
        }
      }
    }
    .highlight.finished {
      border-color: $form-error-color;
      background: rgba(255,255,255,0.1);
    }
  }

  .expected {
    .price {
      background: #264679;
      color: #fff;
      border-radius: 4px;
      padding: 0 9px;
      border: 1px solid #264679;
    }
  }
  .success {
    display: none;
    align-items: center;
    gap: 8px;
  }

  .expected.finished {
    .price {
      background: transparent;
      border-color: transparent;
      color: $brand-color;
    }
  }
  .actual.finished {
    .price {
      background: transparent;
      border-color: transparent;
      color: $form-error-color;
      margin-right: 8px;
      margin-left: 8px;
    }
  }

  .values-wrapper {
    position: relative;
    height: 32px;
    width: 80px;
  }

  .highlight {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 1px solid #fff;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    pointer-events: none;
  }

  .values {
    position: absolute;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    transform: translateY(-11%);
    transition: transform 0.5s ease-in-out;
    font-weight: 600;
  }


  .value {
    font-size: 1.25rem;
    line-height: 1.25rem;
    text-align: center;
    opacity: 1;
    transition: transform 1s ease, opacity 1s ease;
    border: 1px solid transparent;
  }

  /* Trade type labels */
  .trade-type {
    margin-top: 1rem;
    font-size: 1rem;
    position: absolute;
    bottom: 5px;
    font-size: 18px;
    font-weight: 600;
  }
  .fast-trades {
    .trade-type {
      margin-right: 9px;
    }
  }
  .slow-trades {
    .trade-type {
      margin-left: 9px;
    }
  }
}

.no-freezes-section {
  .no-freezes-tag {
    position: absolute;
    bottom: 40%;
    right: 14vw;
    background: #000;
    border-radius: 8px;
    border: 2px solid #D14646;
    color: #fff;
    font-size: 2vw;
    font-weight: 600;
    padding: 8px 16px;
    transition: 1s box-shadow;
    animation: 2s pulseShadow ease-in-out infinite;
    width: 25%;
    text-align: center;
    @media (min-width: 1024px) {
      width: 27%;
      bottom: 37%;
      right: 6vw;
      font-size: 1vw;
    }
    @media (min-width: 1250px) {
      width: 145px;
      bottom: 135px;
      right: 77px;
      font-size: 13px;
    }
    @keyframes pulseShadow {
      0% {
        box-shadow: 0px 0px 18px 0px #D14646;
      }
      50% {
        box-shadow: none;
      }
      100% {
        box-shadow: 0px 0px 18px 0px #D14646;
      }
    }
  }
  .no-freezes-icon {
    position: absolute;
    top: -12px;
    right: -12px;
    background: #D14646;
    color: #fff;
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    font-weight: 600;
    transition: 0.5s transform;
    animation: 0.5s wiggleWiggle ease-in-out infinite;
    @keyframes wiggleWiggle {
      0% {
        transform: rotate(0deg);
      }
      25% {
        transform: rotate(5deg);
      }
      50% {
        transform: rotate(-5deg);
      }
      75% {
        transform: rotate(5deg);
      }
      100% {
        transform: rotate(0deg);
      }
    }
  }
}

.backups-enabled-section {
  .backups-enabled-img {
    position: relative;
    z-index: 0;
    background-position: center top;
    background-size: contain;
    width: 100%;
  }
  .backups-animated-arc1,
  .backups-animated-arc2 {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: auto;
    display: none;
    @media (min-width: 1024px) {
      display: block;
    }
    &__line {
      stroke-dasharray: 140;
      stroke-dashoffset: 140;
      animation: drawEraseLoopBackups 5s ease-in-out infinite;
      filter: drop-shadow(0 0 6px limegreen);
    }
  }
  .backups-animated-arc2__line {
    animation-delay: 2s;
  }
  @keyframes drawEraseLoopBackups {
    0%, 30% {
      stroke-dashoffset: 140; // Hidden (start state)
    }
    70%, 100% {
      stroke-dashoffset: -140; // Erased to start state
    }
  }
}

.cyber-security-section {
  padding-top: var(--basic-section-gap);
  padding-bottom: calc(var(--basic-section-gap) + 75px);
  @media (min-width: 1024px) {
    padding-top: calc(var(--basic-section-gap) + 150px);
    padding-bottom: calc(var(--basic-section-gap) + 150px);
  }
  .cyber-security-animation {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    margin: 70px auto 0;
    width: 250px;
    @media (min-width: 1024px) {
      margin: 0 auto;
      width: 400px;
    }
    .cyber-security-shield {
      position: relative;
      z-index: 0;
      width: 150px;
      @media (min-width: 1024px) {
        width: 240px;
      }
    }
    .cyber-security-ring-inner,
    .cyber-security-ring-1,
    .cyber-security-ring-2,
    .cyber-security-ring-3 {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    .cyber-security-ring-inner {
      animation: rotating 15s linear infinite;
      width: 150px;
      top: calc(50% - 75px);
      left: calc(50% - 75px);
      @media (min-width: 1024px) {
        width: 360px;
        top: calc(50% - 180px);
        left: calc(50% - 180px);
      }
      @keyframes rotating {
        from{
          transform: rotate(0deg);
        }
        to{
          transform: rotate(360deg);
        }
      }
    }
    .cyber-security-attacker {
      position: absolute;
      top: 0px;
      right: -10px;
      animation: security-attacker 5s linear infinite alternate;
      transform: translate(17%, -145%);
      width: 50px;
      height: 50px;
      @media (min-width: 1024px) {
        width: 100px;
        height: 100px;
        top: -45px;
        right: -55px;
      }
    }
    @keyframes security-attacker {
      0% {
        transform: translate(17%, -145%);
      }
      20% {
        transform: translate(-61%, -51%);
      }
      40% {
        transform: translate(76%, 40%);
      }
      60% {
        transform: translate(-4%, 110%);
      }
      80% {
        transform: translate(76%, 230%);
      }
      100% {
        transform: translate(-57%, 269%);
      }
    }
    .ripple-bg {
      @keyframes ripple {
        0% {
          transform: scale(1);
          opacity: 0;
        }

        25% {
          opacity: 0.5;
        }

        75% {
          transform: scale(1.35);
          opacity: 1;
        }

        100% {
          transform: scale(1.5);
          opacity: 0;
        }
      }

      width: 200px;
      height: 200px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 1;
      @media (min-width: 1024px) {
        width: 400px;
        height: 400px;
      }


      &__circles {
        width: 200px;
        height: 200px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        opacity: 0.5;
        @media (min-width: 1024px) {
          width: 400px;
          height: 400px;
        }
        &::before,
        &::after {
          content: '';
          display: block;
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          border-radius: 50%;
          border: 1px solid $brand-color;
        }

        &::before {
          animation: ripple 5s linear infinite;
        }

        &::after {
          animation: ripple 5s linear 2.5s infinite;
        }
      }
    }
  }
}



.markets-move-fast-animation {
  position: relative;
  width: 100%;
  height: calc((100px + 33px) * 3 - 33px);
  overflow: hidden;
  @media (min-width: 768px) {
    height: calc((100px + 33px) * 5 - 33px);
  }
  @media (min-width: 1024px) {
    width: 460px;
  }

  .markets-move-fast-card {
    position: absolute;
    top: -265px;
    left: 0;
    width: 100%;
    height: 100px;
    margin-bottom: 33px;
    display: flex;
    gap: 14px;
    justify-content: space-between;
    align-items: center;
    transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out;
    opacity: 1;
    border-radius: 16px;
    border: 2px solid transparent;
    background: radial-gradient(circle, rgba(26,27,26,1) 0%, rgba(12,13,12,1) 60%) padding-box, linear-gradient(90deg, rgba(45,46,45,0.5) 0%, rgba(107,108,107,0.5) 30%, rgba(45,46,45,0.5) 100%) border-box;
    padding: 16px;
    @media (min-width: 768px) {
      top: 0;
    }
    @media (min-width: 1024px) {
      width: 460px;
    }
    &.active {
      border: 2px solid transparent;
      background: radial-gradient(circle, rgba(14,24,14,1) 0%, rgba(11,12,11,1) 60%) padding-box, linear-gradient(90deg, rgba(42,74,43,0.5) 0%, rgba(120,142,121,0.5) 30%, rgba(45,82,46,0.5) 100%) border-box;
      backdrop-filter: blur(16px);
      .markets-move-fast-card__icon {
        background: linear-gradient(0deg, rgba(77, 228, 80, 0.12) 0%, rgba(77, 228, 80, 0.12) 100%);
        &::after {
          @include sprite('envelope-open');
        }
      }
      .markets-move-fast-card__text {
        p {
          background: transparent;
          color: #fff;
          transition: 0.3s;
          &:last-child {
            opacity: 0.7;
          }
        }
      }
      .markets-move-fast-card__timer {
        .timer-icon {
          display: none;
        }
        .timer {
          display: flex;
        }
      }
    }
    &.answered {
      .markets-move-fast-card__icon {
        &::after {
          @include sprite('envelope-check', (color: #6e6e6e));
        }
      }
    }
    &__icon {
      width: 40px;
      height: 40px;
      position: relative;
      border-radius: 12px;
      background: rgba(235, 249, 245, 0.10);
      padding: 8px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex: 0 0 40px;
      &::after {
        content: '';
        display: block;
        width: 32px;
        height: 32px;
        @include sprite('envelope', (color: #6e6e6e));
        background-position: center center;
        background-size: contain;
      }
    }
    &__text {
      flex: 1 1 100%;
      font-size: 14px;
      display: flex;
      flex-direction: column;
      gap: 4px;
      user-select: none;
      .font-weight-700 {
        font-size: 16px;
      }
      p {
        display: inline-block;
        color: #2E2E2E;
        background: #2E2E2E;
        border-radius: 4px;
        width: fit-content;
      }
    }
    &__timer {
      flex: 0 0 69px;
      .timer-icon {
        display: block;
      }
      .timer {
        display: none;
        position: relative;
        width: 70px;
        height: 70px;
        justify-content: center;
        align-items: center;

        .progress-ring {
          position: absolute;
          transform: rotate(-90deg); // Rotate to start at the top
          &__background {
            stroke-dasharray: 188; /* Same circumference as the progress circle */
            stroke-dashoffset: 0; /* Always visible as the background */
          }
          &__circle {
            stroke-dasharray: 188; // Circumference of the circle (2 * π * radius)
            stroke-dashoffset: 188; // Start fully hidden
            transition: stroke-dashoffset 0.1s linear; // Smooth animation for progress
          }
        }


        .timer-text {
          font-size: 16px;
          font-weight: bold;
          color: $brand-color;
          z-index: 1; // Ensure it's above the SVG
        }
      }
    }
  }
}



.unlock-insights-section {
  position: relative;
  padding: 100px 0;

  .parallax-img-container {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    border: 1px solid #384039;
    background: rgba(77, 228, 80, 0.10);
    backdrop-filter: blur(2.4px);
    padding: 30px;

    img {
      max-width: 100%;
      height: auto;
    }
  }

  .insights-card {
    margin-bottom: 24px;
    border-radius: 8px;
    background: rgba(29, 29, 29, 0.70);
    backdrop-filter: blur(3px);
    padding: 20px;
    display: flex;
    gap: 12px;
    align-items: center;
    @media (min-width: 768px) {
      margin-top: 24px;
    }
    @media (min-width: 1024px) {
      margin-top: 0;
    }
    &__icon-wrap {
      flex: 0 0 65px;
      width: 65px;
      height: 65px;
      border-radius: 50%;
      background: #061C0E;
      padding: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    &__icon {
      color: $brand-color;
      width: 35px;
      height: 35px;
    }
  }
}




.vps-crucial-section {
  .vps-crucial {
    padding-bottom: 100px;

    &__title {
      @include responsive-grid-column(0, 3 / span 8);
      text-align: center;
    }

    .vimeo-player {
      @include responsive-grid-column(0, 2 / span 10);
    }
  }

  @include broker-ribbon;

  .bottom-ribbon {
    bottom: 24px;
  }
}

.remove-stress-section {
  position: relative;
  background-image: url('/img/homepage/remove-stress.png');
  background-size: cover;
  background-position: 100% 50%;
  overflow: visible;

  .remove-stress-content {
    position: relative;
    z-index: 2;
  }
}

.black-friday-img {
  position: absolute;
  z-index: 10;
  width: 150px;
  right: 3px;
  transform: rotate(90deg);
  top: 113px;

  @media(min-width: 576px) {
    width: 165px;
  }

  @media(min-width: 1024px) {
    width: 145px;
  }

  @media(min-width: 1235px) {
    width: 165px;
  }
}
