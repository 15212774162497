// .faq-section {
// }

.faq-list {
  $this: &;

  margin-top: 16px;
  grid-gap: 16px;
  align-items: start;

  @media (min-width: 1024px) {
    margin-top: 24px;
    grid-gap: 24px;
  }

  &__item {
    grid-column: span 4;
    border: 1px solid $brand-color;
    border-radius: 8px;
    transition: background-color 0.25s ease-out;

    &:hover {
      background: rgb($brand-color, 0.1);
    }

    @media (min-width: 1024px) {
      grid-column: span 6;
    }
  }

  &__question {
    position: relative;
    font-weight: 500;
    padding: 16px;
    padding-right: 40px;
    cursor: pointer;
    transition: color 0.25s linear;

    &::after {
      @include svg-arrow;
      content: '';
      display: inline-block;
      width: 13px;
      height: 9px;
      position: absolute;
      top: 20px;
      right: 16px;
      transition: transform 0.15s linear;
    }
  }

  &__answer {
    overflow: hidden;
    max-height: 0;
    transition: max-height 0.25s linear;

    p {
      padding: 0 16px 16px;
    }

    ul {
      padding: 0 16px 16px 32px;
      list-style-type: disc;
    }
  }

  &__item.open {
    background: rgb($brand-color, 0.1);

    #{$this}__question {
      color: $brand-color;

      &::after {
        transform: rotate(180deg);
      }
    }

    #{$this}__answer {
      max-height: 360px;
    }
  }
}

.faq-cta {
  margin-top: 24px;
  text-align: center;

  @media (min-width: 768px) {
    margin-top: 60px;
  }

  .btn {
    @include responsive-grid-column(0, 5 / span 4, 3 / span 4);
  }
}
