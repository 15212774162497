@mixin mt4-equinix-signal {
  @keyframes mt4-equinix-signal {
    0% {
      background: linear-gradient(
        270deg,
        #dcffdd 0%,
        rgba(77, 227, 80, 0.992204) 2.14%,
        rgba(77, 227, 80, 0.3125) 62.59%,
        rgba(77, 227, 80, 0) 102.71%
      );
      transform: translateX(-150%);
    }

    15% {
      transform: translateX(0%);
      background: linear-gradient(
        270deg,
        #dcffdd 0%,
        rgba(77, 227, 80, 0.992204) 2.14%,
        rgba(77, 227, 80, 0.3125) 62.59%,
        rgba(77, 227, 80, 0) 102.71%
      );
    }

    25% {
      transform: translateX(100%);
      background: linear-gradient(
        270deg,
        #dcffdd 0%,
        rgba(77, 227, 80, 0.992204) 2.14%,
        rgba(77, 227, 80, 0.3125) 62.59%,
        rgba(77, 227, 80, 0) 102.71%
      );
    }

    40% {
      transform: translateX(150%);
      background: linear-gradient(
        270deg,
        #dcffdd 0%,
        rgba(77, 227, 80, 0.992204) 2.14%,
        rgba(77, 227, 80, 0.3125) 62.59%,
        rgba(77, 227, 80, 0) 102.71%
      );
    }

    50% {
      transform: translateX(150%);
      background: linear-gradient(
        90deg,
        #dcffdd 0%,
        rgba(77, 227, 80, 0.992204) 2.14%,
        rgba(77, 227, 80, 0.3125) 62.59%,
        rgba(77, 227, 80, 0) 102.71%
      );
    }

    60% {
      transform: translateX(150%);
      background: linear-gradient(
        90deg,
        #dcffdd 0%,
        rgba(77, 227, 80, 0.992204) 2.14%,
        rgba(77, 227, 80, 0.3125) 62.59%,
        rgba(77, 227, 80, 0) 102.71%
      );
    }

    80% {
      transform: translateX(0%);
      background: linear-gradient(
        90deg,
        #dcffdd 0%,
        rgba(77, 227, 80, 0.992204) 2.14%,
        rgba(77, 227, 80, 0.3125) 62.59%,
        rgba(77, 227, 80, 0) 102.71%
      );
    }

    100% {
      transform: translateX(-100%);
      background: linear-gradient(
        90deg,
        #dcffdd 0%,
        rgba(77, 227, 80, 0.992204) 2.14%,
        rgba(77, 227, 80, 0.3125) 62.59%,
        rgba(77, 227, 80, 0) 102.71%
      );
    }
  }
}

@mixin how-it-works-signal {
  @keyframes how-it-works-signal {
    0% {
      background: linear-gradient(
                      270deg,
                      #dcffdd 0%,
                      rgba(77, 227, 80, 0.992204) 2.14%,
                      rgba(77, 227, 80, 0.3125) 62.59%,
                      rgba(77, 227, 80, 0) 102.71%
      );
      transform: translateX(-150%);
    }

    15% {
      transform: translateX(0%);
      background: linear-gradient(
                      270deg,
                      #dcffdd 0%,
                      rgba(77, 227, 80, 0.992204) 2.14%,
                      rgba(77, 227, 80, 0.3125) 62.59%,
                      rgba(77, 227, 80, 0) 102.71%
      );
    }

    25% {
      transform: translateX(150%);
      background: linear-gradient(
                      270deg,
                      #dcffdd 0%,
                      rgba(77, 227, 80, 0.992204) 2.14%,
                      rgba(77, 227, 80, 0.3125) 62.59%,
                      rgba(77, 227, 80, 0) 102.71%
      );
    }

    40% {
      transform: translateX(150%);
      background: linear-gradient(
                      270deg,
                      #dcffdd 0%,
                      rgba(77, 227, 80, 0.992204) 2.14%,
                      rgba(77, 227, 80, 0.3125) 62.59%,
                      rgba(77, 227, 80, 0) 102.71%
      );
    }

    50% {
      transform: translateX(150%);
      background: linear-gradient(
                      90deg,
                      #dcffdd 0%,
                      rgba(77, 227, 80, 0.992204) 2.14%,
                      rgba(77, 227, 80, 0.3125) 62.59%,
                      rgba(77, 227, 80, 0) 102.71%
      );
    }

    60% {
      transform: translateX(150%);
      background: linear-gradient(
                      90deg,
                      #dcffdd 0%,
                      rgba(77, 227, 80, 0.992204) 2.14%,
                      rgba(77, 227, 80, 0.3125) 62.59%,
                      rgba(77, 227, 80, 0) 102.71%
      );
    }

    80% {
      transform: translateX(0%);
      background: linear-gradient(
                      90deg,
                      #dcffdd 0%,
                      rgba(77, 227, 80, 0.992204) 2.14%,
                      rgba(77, 227, 80, 0.3125) 62.59%,
                      rgba(77, 227, 80, 0) 102.71%
      );
    }

    100% {
      transform: translateX(-150%);
      background: linear-gradient(
                      90deg,
                      #dcffdd 0%,
                      rgba(77, 227, 80, 0.992204) 2.14%,
                      rgba(77, 227, 80, 0.3125) 62.59%,
                      rgba(77, 227, 80, 0) 102.71%
      );
    }
  }
}

@mixin how-it-works-signal-vertical {
  @keyframes how-it-works-signal-vertical {
    0% {
      background: linear-gradient(
                      270deg,
                      #dcffdd 0%,
                      rgba(77, 227, 80, 0.992204) 2.14%,
                      rgba(77, 227, 80, 0.3125) 62.59%,
                      rgba(77, 227, 80, 0) 102.71%
      );
      transform: translateY(-150%);
    }

    15% {
      transform: translateY(0%);
      background: linear-gradient(
                      270deg,
                      #dcffdd 0%,
                      rgba(77, 227, 80, 0.992204) 2.14%,
                      rgba(77, 227, 80, 0.3125) 62.59%,
                      rgba(77, 227, 80, 0) 102.71%
      );
    }

    25% {
      transform: translateY(150%);
      background: linear-gradient(
                      270deg,
                      #dcffdd 0%,
                      rgba(77, 227, 80, 0.992204) 2.14%,
                      rgba(77, 227, 80, 0.3125) 62.59%,
                      rgba(77, 227, 80, 0) 102.71%
      );
    }

    40% {
      transform: translateY(150%);
      background: linear-gradient(
                      270deg,
                      #dcffdd 0%,
                      rgba(77, 227, 80, 0.992204) 2.14%,
                      rgba(77, 227, 80, 0.3125) 62.59%,
                      rgba(77, 227, 80, 0) 102.71%
      );
    }

    50% {
      transform: translateY(150%);
      background: linear-gradient(
                      90deg,
                      #dcffdd 0%,
                      rgba(77, 227, 80, 0.992204) 2.14%,
                      rgba(77, 227, 80, 0.3125) 62.59%,
                      rgba(77, 227, 80, 0) 102.71%
      );
    }

    60% {
      transform: translateY(150%);
      background: linear-gradient(
                      90deg,
                      #dcffdd 0%,
                      rgba(77, 227, 80, 0.992204) 2.14%,
                      rgba(77, 227, 80, 0.3125) 62.59%,
                      rgba(77, 227, 80, 0) 102.71%
      );
    }

    80% {
      transform: translateY(0%);
      background: linear-gradient(
                      90deg,
                      #dcffdd 0%,
                      rgba(77, 227, 80, 0.992204) 2.14%,
                      rgba(77, 227, 80, 0.3125) 62.59%,
                      rgba(77, 227, 80, 0) 102.71%
      );
    }

    100% {
      transform: translateY(-150%);
      background: linear-gradient(
                      90deg,
                      #dcffdd 0%,
                      rgba(77, 227, 80, 0.992204) 2.14%,
                      rgba(77, 227, 80, 0.3125) 62.59%,
                      rgba(77, 227, 80, 0) 102.71%
      );
    }
  }
}
