@import '../pricing-card';

.vps-pricing-section {
  overflow: hidden;

  &:target::before {
    content: '';
    display: block;
    height: 100px;
    margin: -50px 0 0;
  }
}

.vps-pricing {
  &-table {
    width: 100%;
    @include responsive-grid-column(0);
    display: none;
    margin-top: 60px;
    @media (min-width: 1200px) {
      display: block;
    }
    thead,
    tbody,
    tr {
      width: 100%;
      display: table;
      table-layout: fixed;
    }
    thead {
      th {
        padding: 24px;
        text-align: center;
        font-size: 24px;
        font-weight: 600;
        .plan-name {
          margin-bottom: 32px;
        }
        .price-row {
          display: flex;
          align-items: center;
          gap: 8px;
          margin-bottom: 8px;
          &:has(+ .price-row__msg.js-price-data:not(.show)) {
            margin-bottom: 40px;
          }
          &__sufix {
            color: #fff;
            font-weight: 500;
            font-size: 16px;
            align-self: flex-end;
            @media (min-width: 768px) {
              padding-top: 0;
            }
          }
          &__slashed {
            position: relative;
            padding-top: 0;
            &::after {
              width: 100%;
              position: absolute;
              height: 1px;
              background: #fff;
            }
          }
          &__value {
            line-height: 1;
            @media (min-width: 768px) {
              font-size: unset;
            }
            &__symbol {
              font-size: 16px;
              font-weight: 600;
              line-height: 130%;
              vertical-align: bottom;
              margin-right: 1px;
            }
            &__number {
              font-size: 40px;
              font-weight: 600;
              letter-spacing: -0.8px;
              vertical-align: bottom;
              &__decimal {
                font-size: 16px;
              }
            }
          }
          &__msg {
            margin-bottom: 24px;
            font-weight: 400;
          }
          ._tag {
            padding: 4px;
            font-size: 12px;
            color: #FFF;
            font-weight: 600;
            border-radius: 2px;
            border: 1px solid #FF5C5C;
            background: linear-gradient(0deg, rgba(3, 3, 3, 0.20) 0%, rgba(3, 3, 3, 0.20) 100%), rgba(228, 77, 77, 0.60);
            display: none;
            @media (max-width: 620px) {
              font-size: 12px;
              padding: 0 4px;
            }
            &.show {
              display: block;
            }
          }
        }
        .btn {
          font-size: 14px;
          font-weight: 500;
        }

        &:nth-child(3) {
          border-top: 1px solid $brand-color;
          border-left: 1px solid $brand-color;
          border-right: 1px solid $brand-color;
          border-radius: 16px 16px 0 0;
        }
      }
    }
    tbody {
      tr:nth-child(odd) {
        td {
          background: #151515;
        }
      }
      tr:nth-child(even) {
        td {
          background: #030303;
        }
      }
      td {
        padding: 22px 24px;
        font-weight: 400;
        text-align: center;
        &:first-child {
          font-weight: 500;
          text-align: left;
          display: flex;
          justify-content: space-between;
        }
        &:nth-child(3) {
          border-left: 1px solid $brand-color;
          border-right: 1px solid $brand-color;
        }
      }
      tr:last-child {
        td:nth-child(3) {
          border-bottom: 1px solid $brand-color;
          border-radius: 0 0 16px 16px;
        }
      }
    }
  }

  .pricing-card {
    font-size: 14px;
    font-weight: 500;
    min-width: 335px;
    &__inner {
      border: none;
    }
    &.popular {
      .pricing-card__inner {
        border: 1px solid $brand-color;
      }
    }
    &__icon {
      flex-basis: auto;
      margin-bottom: 11px;
    }
    &__price {

    }
    .price-row {
      align-items: flex-end;
      line-height: 34px;
      height: 40px;
      vertical-align: bottom;
      &__msg {
        color: $faded-text-color;
        font-weight: 400;
        margin-top: 10px;
        margin-bottom: 4px;
      }
      &__slashed {
        font-size: 12px;
        line-height: 1;
      }
      &__value {
        font-weight: 600;
        line-height: 100%;
        vertical-align: bottom;
        font-size: unset;
        @media (min-width: 768px) {
          font-size: unset;
        }
        &__symbol {
          font-size: 14px;
        }
        &__number {
          font-size: 50px;
          &__decimal {
            font-size: 16px;
          }
        }
      }
      &__sufix {
        color: #fff;
        font-size: 14px;
        line-height: 18px;
        padding-top: 0;
      }
    }
    &__params {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: nowrap;
      border-radius: 8px;
      background: rgba(3, 3, 3, 0.45);
      padding: 8px;
      width: 100%;
      font-size: 12px;
    }
    &__param {
      display: inline-flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      position: relative;
      padding: 0 10px;
      &:not(:first-child) {
        margin-top: 0;
      }
      @media (min-width: 768px) {
        &:not(:first-child) {
          margin-top: 0;
        }
      }
      &::after {
        content: '';
        position: absolute;
        transform: translateY(-50%);
        top: 50%;
        right: 0;
        height: 24px;
        width: 1px;
        background: #fff;
      }
      &:last-child {
        &::after {
          content: none;
        }
      }
    }
    &__features {
      &__item {
        display: flex;
        flex-wrap: nowrap;
        gap: 8px;
        margin-top: 12px;
        .text-branded {
          flex: 0 0 16px;
          position: relative;
          top: 3px;
        }
      }
    }
  }

  &__text,
  &__guarantee {
    color: $faded-text-color;
  }

  &__text {
    margin-top: 8px;
  }

  &__guarantee {
    margin-top: 24px;

    @media (min-width: 768px) {
      text-align: center;
    }
  }

  &__title {
    text-align: left;
    @media (min-width: 1024px) {
      text-align: center;
    }
  }

  &__free {
    margin-top: responsive-rem(10);
    font-size: 12px;
    font-weight: 700;
    line-height: 1;
    text-align: center;

    @media (min-width: 768px) {
      font-size: 24px;
    }
  }

  &__cards {
    margin-top: 24px;

    @media (min-width: 768px) {
      margin-top: responsive-rem(10);
    }
    &-grid {
      grid-template-columns: repeat(5, 1fr);
    }
  }

  .splide {
    &__track {
      overflow: visible;
    }
  }

  &.has-1-cards,
  &.has-2-cards {
    .pricing-card {
      @media (min-width: 768px) {
        width: calc(50% - 12px);
        align-items: baseline;
        max-width: unset;
      }

      @media (min-width: 1280px) {
        width: calc(50% - 20px);
      }

      &__inner {
        width: 100%;
      }
    }
  }

  &.has-1-cards {
    .vps-pricing__title {
      text-align: center;
    }

    .splide__list {
      justify-content: center;
    }
  }

  &.has-3-cards {
    .pricing-card {
      @media (min-width: 768px) {
        width: calc(33% - 12px);
        align-items: baseline;
        max-width: unset;
      }

      @media (min-width: 1280px) {
        width: calc(33% - 20px);
      }

      &__inner {
        width: 100%;
      }
    }
  }

  &__billing {
    font-size: 14px;
    display: flex;
    justify-content: center;
    border: 1px solid $brand-color;
    padding: 5px;
    width: fit-content;
    border-radius: 25px;
    position: relative;
    @media (min-width: 1024px) {
      margin-left: 0 !important;
      margin-right: 0 !important;
    }
    > div {
      display: flex;
      overflow: hidden;
      > div {
        border-radius: 18.5px;
        flex: 0 0 auto;
        text-align: center;
        font-weight: 500;
        font-size: 14px;
        padding: 7px 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        cursor: pointer;
        background: transparent;
        user-select: none;
        white-space: nowrap;
        &:hover {
          color: $brand-color;
        }
        &.selected {
          background-color: $brand-color;
          color: darken($brand-color, 50%);
        }
      }
    }
    > p {
      font-weight: 600;
      margin-bottom: 15px;
    }
    @media (min-width: 1024px) {
      margin-right: auto;
      margin-left: auto;
      text-align: center;
    }
  }
  .saving-tag {
    padding: 4px;
    font-size: 14px;
    color: #FFF;
    font-weight: 600;
    border-radius: 4px;
    border: 0.5px solid #FF5C5C;
    background: linear-gradient(0deg, rgba(3, 3, 3, 0.20) 0%, rgba(3, 3, 3, 0.20) 100%), rgba(228, 77, 77, 0.60);
    @media (max-width: 620px) {
      font-size: 12px;
      padding: 0 4px;
    }
  }


  &__tree {
    color: #fff;

    @media (min-width: 768px) {
      text-align: center;
    }
  }
}

.tree-promise {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 14px;
  img {
    object-fit: contain;
    margin-left: 10px;
    width: 40px;
    margin-right: 5px;
  }

  svg {
    margin: 0 0 -7px 2px;
    @media (min-width: 576px) {
      margin: 0 0 -11px 2px;
    }
  }

  &__text {
    display: block;
    line-height: 1.7;

    @media (min-width: 576px) {
      display: flex;
    }
  }
}

// black friday styles
.pricing-card__inner {
  .black-friday-img {
    position: absolute;
    z-index: 10;
    transform: rotate(0deg);
    right: unset;
    left: 0;
    width: 100px;
    top: 0;
  }
}

.custom-vps-order {
  background: #151515;
  padding: 16px;
  border-radius: 8px;
  flex-direction: column;
  gap: 11px;
  &.mobile {
    display: flex;
    @media (min-width: 1024px) {
      display: none;
    }
  }
  &.desktop {
    display: none;
    @media (min-width: 1024px) {
      display: flex;
    }
  }
  @media (min-width: 1024px) {
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
  }
  &__content {
    @media (min-width: 1024px) {
    }
  }
  .content-outer {
    flex: 1 1 80%;
  }
  &__icon {
    width: 35px;
    height: 35px;
    padding: 5px;
    border-radius: 50%;
    background: #061C0E;
    color: $brand-color;
  }
  &__title {
    font-size: 20px;
    font-weight: 700;
    display: flex;
    align-items: center;
    gap: 8px;
  }
  &__text {
    font-size: 14px;
    gap: 3px;
    svg {
      vertical-align: middle;
    }
    @media (min-width: 1024px) {
      display: flex;
      align-items: center;
    }
  }
  .btn {
    @media (min-width: 1024px) {
      flex: 1 1 20%;
    }
  }
}
